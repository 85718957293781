import React, { Component } from "react";
import { Card, CardBody, Col, Row, CardTitle, Alert, Container, Button, Table, Badge, CardHeader, CardFooter, Modal, FormGroup } from "reactstrap";
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
// import {CustomPagination} from "./Common/CustomPagination";
// import {DataTabeSearch} from './Common/DataTabeSearch'
// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { createParkIn, searchByParkOutReport, summaryParking, summaryTicket, ticketFistLoad, ticketReport } from '../../store/park/actions';
import { rateTypeList, vehicleTypeList } from '../../store/parkingRate/actions';
import { companyInfo } from '../../store/bill/actions';
import './summary.scss';
import DatePicker from "react-datepicker";
import { Multiselect } from 'multiselect-react-dropdown';
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../pages/Loader/Loader";
import { accountInfo } from "../../store/actions";
import moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';
import logo from "../../assets/images/logo-light.png";

import '../../assets/fonts/Nikosh-normal.js';

class SummaryReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            per_page: 10,
            search: '',
            customchk: true,
            loading: false,
            toggleSwitch: false,
            // startDate: new Date().toISOString().split('T')[0],
            startDate: new Date(),
            endDate: new Date(),
            edittoggleSwitch: false,
            modal_scroll: false,
            slot: {

            }
        };


        // this.tog_scroll = this.tog_scroll.bind(this);

    }





    componentDidMount() {
        this.fetchRateTypeList();
        this.fetchAccountInfo();
        this.fetchVehicleTypeList();
        this.fetchCompanyInfo();
    }
    fetchAccountInfo = () => {
        accountInfo().then(response => {
            this.setState({
                accountInfo: response.data
            })
        })
    }

    fetchRateTypeList = () => {
        rateTypeList().then((response) => {
            this.setState({
                rateTypeList: response.data.rateType,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    fetchVehicleTypeList = () => {
        vehicleTypeList().then((response) => {
            this.setState({
                vehicleTypeList: response.data.vehicleType,
            });
        })
            .catch((err) => {
                console.log(err);
            })
    }

    //modal
    tog_scroll = (slot) => {
        this.setState(prevState => ({
            modal_scroll: !prevState.modal_scroll,
        }));
        this.state.slot = slot;
        this.removeBodyCss();
    }

    // background-color: rgba(255, 255, 255, 0.9) !important; 
    printDiv = (e, startDate, endDate) => {
        e.preventDefault();
        var content = document.getElementById('ForPrint').innerHTML;
        var title = document.title + "-" + moment(new Date(startDate)).format("DD-MM-YYYY") + "-to-" + moment(new Date(endDate)).format("DD-MM-YYYY");
        var printWindow = window.open("", "_blank", "width=800,height=600");
        printWindow.document.open();
        printWindow.document.write('<html><head><title>' + title + '</title><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"><style>footer { font-size: 10px; text-align: center;} @media print { footer { position: fixed; bottom: 0;} .content-block, p { page-break-inside: avoid;} html, body { width: 210mm; height: 297mm; }.table-bordered th, .table-bordered td {height:40px;border:1px solid #000000 !important; -webkit-print-color-adjust:exact;} .table-report th, td {height:40px;padding:0.25rem !important;border:1px solid #000000 !important; -webkit-print-color-adjust:exact;} .bg-text img{ position:absolute !important;z-index:4, height:350px !important; width:350px !important; opacity:0.3; top:15% ; right:0; left:35%; bottom:0} .bg-text{ font-weight: bold !important;  position: absolute!important; top: 50%!important; left: 50%!important; transform: translate(-50%, -50%)!important;z-index: 2!important;width: 100%!important; padding: 150px!important; text-align: center!important; -webkit-print-color-adjust:exact!important; }}</style></head><body>');
        printWindow.document.write(content);
        printWindow.document.write('<footer>Printed at: ' + new Date().toLocaleString() + '</footer></body></html>');
        printWindow.document.close();
        printWindow.focus();
        setTimeout(function () {
            printWindow.print();
            printWindow.close();
        }, 1000);
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {
        this.state.loading = true;
        this.setState({
            startDate: values.start_date,
            endDate: values.end_date,
        })
        let startDate = values && moment(new Date(values.start_date)).format("YYYY-MM-DD");
        let endDate = values && moment(new Date(values.end_date)).format("YYYY-MM-DD");
        let ticketData = {
            startDate: startDate,
            endDate: endDate,
        }
        let parkingData = {
            start_date: startDate,
            end_date: endDate,
        }


        summaryTicket(ticketData).then((response) => {
            this.setState({
                totalTicket: response.data,
                ticketSummary: response.data.park_ratewise_income,
                loading: false,
            });
        })
        summaryParking(parkingData).then((response) => {
            this.setState({
                parkingSummary: response.data.parkOut,
                loading: false,
            });
            // console.log(this.state.parkingSummary);
        })

            .catch((err) => {
                this.state.loading = false;
                console.log(err);
            })
        // this.form && this.form.reset();

    }



    fetchCompanyInfo = () => {

        companyInfo().then((response) => {
            this.setState({
                company: response.data
            })
        })
            .catch((err) => {
                console.log(err);
            })

    }


    downloadPDF = (ticketSummary, reportDetails, netAmount, total_quantity, accountInformation, mobile, grandQuantity, grandAmount, grandPrice, startDate, endDate) => {
        // html2canvas(window.document.querySelector("#"+divID)).then(canvas => {
        //        const imgData = canvas.toDataURL('image/png');
        //         var imgWidth = 210; 
        //         var pageHeight = 296;  
        //         var imgHeight = canvas.height * imgWidth / canvas.width;
        //         var heightLeft = imgHeight;


        //         var doc = new jsPDF('p', 'mm');
        //         var position = 0;

        //         doc.autoTable(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        //         heightLeft -= pageHeight;

        //         while (heightLeft >= 0) {
        //             position = heightLeft - imgHeight;
        //             doc.addPage();
        //             doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

        //             heightLeft -= pageHeight;
        //         }
        //         doc.save( 'file.pdf');
        // });

        // console.log(ticketSummary, reportDetails, total_quantity, netAmount, accountInformation, mobile, grandQuantity,grandAmount,grandPrice);

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFont('Nikosh'); //

        doc.setFontSize(15);
        // const Information = [{ header: "", name: accountInformation.park_name_en }, { header: "", name: accountInformation.address }, { header: "ইজারাদার", name: accountInformation.lease_holder }, { header: "স্মারক নং", name: accountInformation.memorandum_no }, { header: "ফোন:", name: mobile }, { header: "প্রবেশ টিকেট:", name: accountInformation.lease_year }]
        // const title = Information.map(header => [header.header, header.name]);


        const headers2 = [["No", "Date & Time", "Token", "Ticket Type", "Price", "Quantity", "Amount", "Is Family", "Operate By", "Device"]];
        const headers1 = [["TICKET TYPE", "PRICE", "Quantity", "Net Amount"]];
        const summaryTotal = [["", "Total", total_quantity, netAmount]]
        const data = ticketSummary.map(elt => [elt.ticket_type, elt.rate, elt.total_ticket_qty, elt.total_income]);
        const merged = [...data, ...summaryTotal]

        const data2 = reportDetails.map((ticket, index) =>
            [index + 1,
            moment.unix(ticket.ticket_entry_date_ime / 1000).format("DD MMM YYYY hh:mm a"),
            ticket.ticket_no,
            ticket.park_rate_type == null || ticket.park_rate_type == "" ? "-" : ticket.park_rate_type.ticket_type,
            ticket.ticket_price,
                1,
            ticket.ticket_price,
            ticket.family_id == null || ticket.family_id == "" ? "-" : ticket.family_id,
            ticket.insert_user == null || ticket.insert_user == "" ? ticket.insert_by_id : ticket.insert_user.name,
            ticket.api_device_name == null || ticket.api_device_name == "" ? "-" : ticket.api_device_name]
        )

        let grandTolal = [["", "", "", "Grand Total", grandPrice, grandQuantity, grandAmount, "", "", ""]];

        const merged2 = [...data2, ...grandTolal]

        let content = {
            startY: 180,
            head: headers1,
            body: merged
        };

        let content1 = {
            startY: 300,
            head: headers2,
            body: merged2
        };


        doc.text(accountInformation.park_name_en, marginLeft, 40);
        doc.text(accountInformation.address, marginLeft, 60);
        doc.text("ইজারাদার: " + accountInformation.lease_holder, marginLeft, 80);
        doc.text("স্মারক নং " + accountInformation.memorandum_no, marginLeft, 100);
        doc.text("ফোন: " + mobile, marginLeft, 120);
        doc.text("প্রবেশ টিকেট: " + accountInformation.lease_year, marginLeft, 140);
        doc.text("তারিখ: " + startDate + " থেকে " + endDate, marginLeft, 160);
        doc.autoTable(content);
        doc.autoTable(content1);
        doc.save("report.pdf")
    }

    render() {
        if (this.state.loading) return <Loader />;

        let vehicleTypeList = [];
        let rateTypeList = [];
        let reportDetails = [];
        let ticketSummary = [];
        let parkingSummary = [];
        let mobile = '';

        let total_quantity = 0;
        let netAmount = 0;
        let accountInformation = [];
        if (this.state.hasOwnProperty('accountInfo')) {
            accountInformation = this.state.accountInfo.park_account_info[0];
        }

        let park_total_quantity = 0;
        let park_netAmount = 0;
        let park_totalHour = 0;

        // if (this.state.hasOwnProperty('details_report_first_ten')) {
        //     detailsReportFirst = this.state.details_report_first_ten
        // }
        // if (this.state.hasOwnProperty('details_report_last_ten')) {
        //     detailsReportLast = this.state.details_report_last_ten
        // }
        // if (this.state.hasOwnProperty('park_ratewise_income')) {
        //     parkIncome = this.state.park_ratewise_income
        // }

        // if (this.state.hasOwnProperty('ticket')) {
        //     ticketQuantity = this.state.ticket.total_ticket
        //     ticketAmount = this.state.ticket.total_ticket_sum
        // }
        // console.log('detailsReport1', detailsReportFirst)
        // console.log('detailsReport2', detailsReportLast)
        // console.log('parkIncome', parkIncome)


        // if (this.state.hasOwnProperty('detailsReport')) {
        //     reportDetails = this.state.detailsReport;

        //     reportDetails && reportDetails.map((element, index) => {
        //         grandPrice += element.ticket_price;
        //         grandAmount += element.ticket_price;
        //     });

        // }
        // if (this.state.hasOwnProperty('totalTicket')) {
        //     grandQuantity = this.state.totalTicket.total_ticket;
        // }

        if (this.state.hasOwnProperty('ticketSummary')) {
            ticketSummary = this.state.ticketSummary;
            ticketSummary && ticketSummary.map((element, index) => {
                total_quantity += element.total_ticket_qty;
                netAmount += element.total_income * 1;
            });
        }


        if (this.state.hasOwnProperty('parkingSummary')) {
            parkingSummary = this.state.parkingSummary;
            parkingSummary && parkingSummary.map((element, index) => {
                park_total_quantity += element.total_vehicle;
                park_netAmount += element.total_receive_amount * 1;
                park_totalHour += element.total_hour_spend;

            });
        }

        let grandQuantity = total_quantity + park_total_quantity;
        let grandAmount = netAmount + park_netAmount;
        // if (this.state.hasOwnProperty('company')) {
        //     company_name = this.state.company.name;
        //     address = this.state.company.address;
        //     mobile = this.state.company.mobile;
        // }


        var finalEnglishToBanglaNumber = {
            0: "০",
            1: "১",
            2: "২",
            3: "৩",
            4: "৪",
            5: "৫",
            6: "৬",
            7: "৭",
            8: "৮",
            9: "৯",
        };

        String.prototype.getDigitBanglaFromEnglish = function () {
            var retStr = this;
            for (var x in finalEnglishToBanglaNumber) {
                retStr = retStr.replace(
                    new RegExp(x, "g"),
                    finalEnglishToBanglaNumber[x]
                );
            }
            return retStr;
        };


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <title>Summary-Report</title>
                        <Breadcrumbs title="Settings" breadcrumbItem="Summary Report" />
                        {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                        {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}
                        <Row>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Summary Report</CardTitle>

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { this.handleValidSubmit(e, v) }} ref={c => (this.form = c)}>

                                            <FormGroup row>

                                                <div className="col-md-6 col-sm-12 col-xs-12">
                                                    <label>Date Range</label>
                                                    <div class="row">
                                                        <div className="col pr-0">
                                                            <DatePicker
                                                                selected={this.state.startDate}
                                                                className="form-control"
                                                                onChange={date => this.setState({ startDate: date })}
                                                                selectsStart
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                            />
                                                        </div>
                                                        <div className="col pl-0">
                                                            <DatePicker
                                                                selected={this.state.endDate}
                                                                className="form-control"
                                                                onChange={date => this.setState({ endDate: date })}
                                                                selectsEnd
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                                minDate={this.state.startDate}
                                                            />
                                                        </div>
                                                        <div className="col">
                                                            <Button type="submit" color="success" className="btn btn-md">Search</Button>
                                                        </div>
                                                    </div>


                                                    <AvField type="hidden" name="start_date" id="start_date" value={this.state.startDate} />
                                                    <AvField type="hidden" name="end_date" id="end_date" value={this.state.endDate} />
                                                </div>
                                            </FormGroup>

                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="12" md="12">
                                <Card>
                                    <CardHeader>
                                        <FormGroup row>
                                            <div class="col-md-6">
                                                <CardTitle> Summary REPORT</CardTitle>
                                            </div>
                                            <div class="col-md-6">
                                                {/* {reportDetails.length == 0 ? "" :
                                                    <button size="lg" className="btn btn-md  ml-2 text-light  float-right" style={{ backgroundColor: '#4E949B' }} onClick={() => this.downloadPDF(ticketSummary, reportDetails, total_quantity, netAmount, accountInformation, mobile.getDigitBanglaFromEnglish(), grandQuantity, grandAmount, grandPrice, moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish(), moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish())}>PDF</button>} */}
                                                <button size="lg" className="btn btn-md btn-primary float-right" onClick={(e) => this.printDiv(e, this.state.startDate, this.state.endDate)}>PRINT</button>
                                            </div>
                                        </FormGroup>
                                    </CardHeader>
                                    {/* { reportDetails.length == 0 ? */}
                                    <>
                                        <CardBody>
                                            <div id="example">

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        <h2 className="text-center"><b>{accountInformation.park_name_en}</b></h2>
                                                        <span className="text-center">{accountInformation.address}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{accountInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{accountInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {accountInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br>
                                                    </div>

                                                </div>
                                                <div className="row waterMark">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        {/* <h5 className="title-summary font-weight-bold text-left mb-2 text-primary">Ticket Summary</h5> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Ticket Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Ticket Type</th>
                                                                    <th className="text-center">Price</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ticketSummary && ticketSummary.map((ticketSummary, index) =>
                                                                    <tr key={ticketSummary.id.toString()}>
                                                                        <td className="text-center">{ticketSummary.ticket_type}</td>
                                                                        <td className="text-right">{ticketSummary.rate} TAKA</td>
                                                                        <td className="text-right">{ticketSummary.total_ticket_qty} Qty</td>
                                                                        <td className="text-right">{ticketSummary.total_income} TAKA</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{netAmount} TAKA</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        {/* <h5 className="title-summary font-weight-bold text-left mb-2 text-primary">Parking Summary</h5> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Parking Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Vehicle Type</th>
                                                                    {/* <th className="text-center">Price</th> */}
                                                                    <th className="text-center">Hours</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingSummary && parkingSummary.map((parkingSummary, index) =>
                                                                    <tr key={parkingSummary.id.toString()}>
                                                                        <td className="text-center">{parkingSummary.vehicle_type_name_en}</td>
                                                                        <td className="text-right">{parkingSummary.total_hour_spend} Hours</td>
                                                                        <td className="text-right">{parkingSummary.total_vehicle} Qty</td>
                                                                        <td className="text-right">{parkingSummary.total_receive_amount} TAKA</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="1" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{park_totalHour} Hours</td>
                                                                    <td className="text-right font-weight-bold">{park_total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{park_netAmount} TAKA</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        {/* <h5 className="title-summary font-weight-bold text-left mb-2 text-primary">Parking Summary</h5> */}
                                                        {/* <Table bordered responsive className="table-report">
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold ">Grand Total</td>
                                                                    <td className="text-right font-weight-bold ">{grandQuantity} Qty</td>
                                                                    <td className="text-right font-weight-bold ">{grandAmount} TAKA</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2" className="text-center font-weight-bold align-middle">Grand Total</th>
                                                                    <th className="text-center font-weight-bold ">Net Amount</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center font-weight-bold ">{grandAmount} TAKA</th>
                                                                </tr>
                                                            </thead>

                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>

                                        </CardBody>

                                        {/* for Print Only */}
                                        <CardBody style={{ display: 'none' }}>
                                            <div id="ForPrint">

                                                <div className="row">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        <h2 className="text-center"><b>{accountInformation.park_name_en}</b></h2>
                                                        <span className="text-center">{accountInformation.address}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ইজারাদার: </span>{accountInformation.lease_holder}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">স্মারক নং: </span>{accountInformation.memorandum_no}</span><br></br>
                                                        <span className="text-center"><span className="font-weight-bold">ফোন: </span>{mobile.getDigitBanglaFromEnglish()}</span>
                                                        <h6><span className="font-weight-bold">প্রবেশ টিকেট: {accountInformation.lease_year}</span></h6>
                                                        <p><span className="font-weight-bold">তারিখ: </span>{moment(new Date(this.state.startDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()} থেকে {moment(new Date(this.state.endDate)).format("DD/MM/YYYY").getDigitBanglaFromEnglish()}</p>
                                                        <br></br>
                                                    </div>

                                                </div>
                                                <div className="row waterMark">
                                                    <div className="col-12 col-lg-6 offset-lg-3" align="center">
                                                        {/* <h5 className="font-weight-bold text-left mb-2 text-primary">Ticket Summary</h5> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Ticket Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Ticket Type</th>
                                                                    <th className="text-center">Price</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ticketSummary && ticketSummary.map((ticketSummary, index) =>
                                                                    <tr key={ticketSummary.id.toString()}>
                                                                        <td className="text-center">{ticketSummary.ticket_type}</td>
                                                                        <td className="text-right">{ticketSummary.rate} TAKA</td>
                                                                        <td className="text-right">{ticketSummary.total_ticket_qty} Qty</td>
                                                                        <td className="text-right">{ticketSummary.total_income} TAKA</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{netAmount} TAKA</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>

                                                    <div className="bg-text">
                                                        <img src={logo} className=" w-100 " alt="image" />
                                                    </div>


                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        {/* <h5 className="font-weight-bold text-left mb-2 text-primary">Parking Summary</h5> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr><th colSpan={4} style={{ textAlign: 'center' }}>Parking Summary</th></tr>
                                                                <tr>
                                                                    <th className="text-center">Vehicle Type</th>
                                                                    {/* <th className="text-center">Price</th> */}
                                                                    <th className="text-center">Hours</th>
                                                                    <th className="text-center">Quantity</th>
                                                                    <th className="text-center">Net Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {parkingSummary && parkingSummary.map((parkingSummary, index) =>
                                                                    <tr key={parkingSummary.id.toString()}>
                                                                        <td className="text-center">{parkingSummary.vehicle_type_name_en}</td>
                                                                        <td className="text-right">{parkingSummary.total_hour_spend} Hours</td>
                                                                        <td className="text-right">{parkingSummary.total_vehicle} Qty</td>
                                                                        <td className="text-right">{parkingSummary.total_receive_amount} TAKA</td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td colSpan="1" className="text-right font-weight-bold"> Total</td>
                                                                    <td className="text-right font-weight-bold">{park_totalHour} Hours</td>
                                                                    <td className="text-right font-weight-bold">{park_total_quantity} Qty</td>
                                                                    <td className="text-right font-weight-bold">{park_netAmount} TAKA</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    <div className="col-12 col-lg-6 offset-lg-3 mt-3" align="center">
                                                        {/* <h5 className="title-summary font-weight-bold text-left mb-2 text-primary">Parking Summary</h5> */}
                                                        {/* <Table bordered responsive className="table-report">
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="2" className="text-right font-weight-bold ">Grand Total</td>
                                                                    <td className="text-right font-weight-bold ">{grandQuantity} Qty</td>
                                                                    <td className="text-right font-weight-bold ">{grandAmount} TAKA</td>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                        <Table bordered responsive className="table-report">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2" className="text-center font-weight-bold align-middle">Grand Total</th>
                                                                    <th className="text-center font-weight-bold ">Net Amount</th>
                                                                </tr>
                                                                <tr>
                                                                    <th className="text-center font-weight-bold ">{grandAmount} TAKA</th>
                                                                </tr>
                                                            </thead>

                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>

                                        </CardBody>
                                    </>

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { msg, error, success } = state.ParkingSlot;
    return { msg, error, success };
}

export default withRouter(connect(mapStatetoProps, { createParkIn })(SummaryReport));
