import { USER_ERROR,USER_SUCCESS,EDIT_USER,SHOW_USER,CREATE_USER } from './actionTypes';
import {getApi} from "../../services/httpService";

export const createUser = (user) => {
    return {
        type: CREATE_USER,
        payload: { user }
    }
}

export const showUser = (user_id) => {
    return {
        type: SHOW_USER,
        payload: { user_id }
    }
}

export const editUser = (user) => {
    return {
        type: EDIT_USER,
        payload: { user }
    }
}



export const userSuccess = (msg) => {
    console.log('msg',msg)
    return {
        type: USER_SUCCESS,
        payload: msg
    }
}


export function userShow(user_id){
    return getApi().get('user/'+user_id)
}

export function accountInfo(){
    return getApi().get('parkSettings/account/info')
}


export function userList(params){
    return getApi().get('user/list',{
        params: params
    })
}

export function userSearchList(keyword,params){
    return getApi().get('user/search/'+keyword,{
        params: params
    })
}

export function userVehicleList(keyword,params){
    return getApi().get('userVehicleMapping/search/'+keyword,{
        params: params
    })
}



export const userError = (error) => {
    return {
        type: USER_ERROR,
        payload: error
    }
}
