import React, { Component } from "react";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withNamespaces } from 'react-i18next';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        let menubar = JSON.parse(localStorage.getItem("menubar"));
        menubar = Object.keys(menubar).map(key => menubar[key]);

        return (
            <React.Fragment>
                <div className="topnav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                {/* {menubar.map((menu, i) => ( */}
                                <ul className="navbar-nav" >
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none" to="/dashboard" style={{color: "white"}}>
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Home')}
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none" to="/park/ticket/transactions" style={{color: "white"}}>
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Transaction')}
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none"  onClick={e => { e.preventDefault(); this.setState({ isPermission: !this.state.isReport }); }} to="report" style={{color: "white"}}>
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Report')}<div className="arrow-down"></div>
                                        </Link>
                                        <div className={classname("dropdown-menu", { show: this.state.isReport })}>
                                                <Link to="/park/report" className="dropdown-item">{this.props.t('Ticket Report')}</Link>
                                                <Link to="/summary/report" className="dropdown-item">{this.props.t('Summary Report')}</Link>
                                            </div>              
                                    </li>
                                    
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle arrow-none" onClick={e => { e.preventDefault(); this.setState({ isPermission: !this.state.isPermission }); }} to="permission" style={{color: "white"}}>
                                                <i className="bx bx-home-circle mr-2"></i>{this.props.t('Permission')} {this.props.menuOpen}<div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.isPermission })}>
                                                <Link to="/user/create" className="dropdown-item">{this.props.t('User')}</Link>
                                                {/* <Link to="/roleList" className="dropdown-item">{this.props.t('Role')}</Link> */}
                                                <div className="dropdown">
                                                    <Link
                                                        to="/#"
                                                        className="dropdown-item dropdown-toggle arrow-none"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({
                                                                role: !this.state.role,
                                                            });
                                                        }}
                                                    >
                                                        {this.props.t("Role")} <div className="arrow-down" />
                                                    </Link>
                                                    <div
                                                        className={classname("dropdown-menu", {
                                                            show: this.state.role,
                                                        })}
                                                    >
                                                        <Link to="/roleList" className="dropdown-item">
                                                            {this.props.t("Roles List")}
                                                        </Link>
                                                        <Link to="/role" className="dropdown-item">
                                                            {this.props.t("Create Role")}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>              
                                        </li>
                                    </ul>
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            <Link className="nav-link dropdown-toggle arrow-none" onClick={e => { e.preventDefault(); this.setState({ isPark: !this.state.isPark }); }} to="parkSettings" style={{color: "white"}}>
                                                <i className="bx bx-home-circle mr-2"></i>{this.props.t('Park Settings')} {this.props.menuOpen}<div className="arrow-down"></div>
                                            </Link>
                                            <div className={classname("dropdown-menu", { show: this.state.isPark })}>
                                                <Link to="/parkRate" className="dropdown-item">{this.props.t('Park Rate')}</Link>
                                                <Link to="/parkDeviceAuthorization" className="dropdown-item">{this.props.t('Device Permission')}</Link>
                                                <Link to="/parkInfo" className="dropdown-item">{this.props.t('Information')}</Link>
                                            </div>
                                        </li>
                                    </ul>

                                    {/* <li className="menu-title color-white">{this.props.t(menu.module_name_en) }</li>
                                    {(Object.keys(menu.group).map(key => menu.group[key])).map((group, j) => (
                                    <li className="color-white">
                                            <Link to={(group.is_children == 0)?group.children[0].react_url:'/#' } className="waves-effect">
                                                    <i className="bx bx-home-circle"></i>
                                                    <span>{this.props.t(group.main_menu_name) }</span>
                                            </Link>
                                        
                                        {group.is_children == 1 &&
                                            <ul className="sub-menu " aria-expanded="false" >
                                                {Object.keys(group.children).map(key => group.children[key]).map((children, k) => (
                                                    <li className="color-white"><Link to={children.react_url}>{this.props.t(children.menu_name) }</Link></li>
                                                ))}
                                            </ul>
                                        }
                                    </li>
                                    ))} */}
                                </ul>
                                {/* ))} */}
                                {/* <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none" onClick={e => { e.preventDefault(); this.setState({ isDashboard: !this.state.isDashboard }); }} to="dashboard">
                                            <i className="bx bx-home-circle mr-2"></i>{this.props.t('Dashboard')} {this.props.menuOpen}<div className="arrow-down"></div>
                                        </Link>
                                        <div className={classname("dropdown-menu", { show: this.state.isDashboard })}>
                                            <Link to="index" className="dropdown-item">{this.props.t('Default')}</Link>
                                        </div>
                                    </li>

                                  
                                </ul> */}
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(Navbar));
